import React from 'react';
// react-router-dom
import { Outlet } from 'react-router-dom';
// mui
// routes config
// components
import RootLayout from './RootLayout';
//

function LayoutWrapper() {
  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
}
export default LayoutWrapper;
