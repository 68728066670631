import React from 'react';
//
import './notFoundPage.scss';
import { Container, Grid } from '@mui/material';

export default function NotFoundPage() {
  return (
    <Container>
      <Grid container justifyContent="center" direction="column">
        <Grid item>
          <h1 className="text-center ">404</h1>
        </Grid>
        <Grid item>
          <h3 className="h2">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Look like you're lost
          </h3>
          <p>the page you are looking for not avaible!</p>
        </Grid>
      </Grid>
    </Container>
  );
}
