import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCoySjWBzyC-pXCJi1R0VKAbPWt4lZ9z-4',
  authDomain: 'webdev-386a1.firebaseapp.com',
  projectId: 'webdev-386a1',
  storageBucket: 'webdev-386a1.appspot.com',
  messagingSenderId: '552129685484',
  appId: '1:552129685484:web:d426f8acd19966849342c9',
};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}
const app = getApps()[0];
const db = getFirestore(app);
const auth = getAuth(app);

const storage = getStorage(app);

export {
  app, db, auth, firebaseConfig, storage,
};
