import { useEffect } from 'react';
import { db } from '../config/config-firebase';
import { doc, getDoc } from 'firebase/firestore';
//

import AuthAction, { ProjectInfo } from '../reduxStore/authReducer/authAction';
import { useAppDispatch, useAppSelector } from '../reduxStore/utils/reduxHooks';
import { fsApiConfigCol } from '../config/config-firestore';

const fetchTokens = async (token?:string):Promise<ProjectInfo[]> => {
  try {
    if (!token) return [];
    const docRef = doc(db, fsApiConfigCol, token);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      const data: { [key:string]: { token:string; } } = docSnap.data();
      return Object.keys(data).reduce<ProjectInfo[]>((acc, key) => {
        acc.push({
          project: key,
          token: data[key].token,
        });
        return acc;
      }, []);
    }
    // docSnap.data() will be undefined in this case
    console.log('No such document!');
    return [];
  } catch (e) {
    return [];
  }
};

const firestoreListener = () => {
  const authData = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { token } = authData;

  useEffect(() => {
    if (token) {
      fetchTokens(token)
        .then((data) => {
          console.log('[firestoreListener] data', data);
          dispatch({
            type: AuthAction.SET_PRJ_TOKEN,
            payload: data,
          });
        });
    }
  }, [token]);

  return null;
};

export default firestoreListener;
