import axios from 'axios';
//
const langPath = process.env.REACT_APP_EASY_API_LANG || 'en';
//
const easyApiBase = process.env.REACT_APP_EASY_API_NODE;
const tsgcApiBase = process.env.REACT_APP_TSGC_API_NODE;
const nmtlApiBase = process.env.REACT_APP_NMTL_API_NODE;
const hkbdbApiBase = process.env.REACT_APP_HKBDB_API_NODE;
const lxtApiBase = process.env.REACT_APP_LXT_API_NODE;
const tlitApiBase = process.env.REACT_APP_TLIT_API_NODE;
const dppApiBase = process.env.REACT_APP_DPP_API_NODE;
//
const easyApi = `${easyApiBase}/${langPath}`;
const tsgcApi = `${tsgcApiBase}/${langPath}`;
const nmtlApi = `${nmtlApiBase}/${langPath}`;
const hkbdbApi = `${hkbdbApiBase}/${langPath}`;
const lxtApi = `${lxtApiBase}`;
const tlitApi = `${tlitApiBase}/${langPath}`;
const dppApi = `${dppApiBase}/${langPath}`;

// const testUrl = 'http://localhost:3030';
// 預設語系
const defaultLocale = 'en';
// 當前語系
let locale = defaultLocale;

const services = {
  setLocale(lang: string) {
    locale = lang;
  },
  getDefaultLocale(): string {
    return defaultLocale;
  },
  getLocale(): string {
    return locale;
  },
  setAxiosAuth(token: string) {
    // 不加 prefix "Bearer "
    axios.defaults.headers.Authorization = `${token}`;
    axios.defaults.headers.common.Authorization = `${token}`;
  },
  // easy-api health
  getEasyHealth: () => `${easyApi}/health`,
  // easy-api ontology
  getEasyProtegeList: () => `${easyApi}/ontology/protege/list/1.0?limit=-1&offset=0`,
  getEasyClassList: () => `${easyApi}/ontology/class/list/1.0?limit=-1&offset=0`,
  getEasyGraphClassCount: () => `${easyApi}/ontology/graph/class/count/1.0?limit=-1&offset=0`,
  getEasyGraphTplCount: () => `${easyApi}/ontology/graph/triple/count/1.0?limit=-1&offset=0`,

  // tsgc-api health
  getTsgcHealth: () => `${tsgcApi}/health`,
  // easy-api ontology
  getTsgcProtegeList: () => `${tsgcApi}/ontology/protege/list/1.0?limit=-1&offset=0`,
  getTsgcClassList: () => `${tsgcApi}/ontology/class/list/1.0?limit=-1&offset=0`,
  getTsgcGraphClassCount: () => `${tsgcApi}/ontology/graph/class/count/1.0?limit=-1&offset=0`,
  getTsgcGraphTplCount: () => `${tsgcApi}/ontology/graph/triple/count/1.0?limit=-1&offset=0`,

  // nmtl-api health
  getNmtlHealth: () => `${nmtlApi}/health`,
  // nmtl-api ontology
  getNmtlProtege: () => `${nmtlApi}/ontology/protege/list/1.1?limit=-1&offset=0`,
  getNmtlClassList: () => `${nmtlApi}/ontology/class/list/1.0?limit=-1&offset=0`,

  // hkbdb-api health
  getHkbdbHealth: () => `${hkbdbApi}/health`,
  // hkbdb-api ontology
  getHkbdbProtege: () => `${hkbdbApi}/ontology/protege/list/2.0?limit=-1&offset=0`,
  getHkbdbClassList: () => `${hkbdbApi}/ontology/class/list/1.0?limit=-1&offset=0`,

  // lxt-api health
  // getLxtHealth: `${lxtApi}/health`,
  // lxt-api ontology
  getLxtProtege: () => `${lxtApi}/ontology/protege/list/1.0?limit=-1&offset=0`,
  getLxtClassList: () => `${lxtApi}/ontology/class/list/1.0?limit=-1&offset=0`,

  // tlit-api ontology
  getTlitHealth: () => `${tlitApi}/health`,
  // tlit-api ontology
  getTlitProtegeList: () => `${tlitApi}/ontology/protege/list/1.0?limit=-1&offset=0`,
  getTlitClassList: () => `${tlitApi}/ontology/class/list/1.0?limit=-1&offset=0`,
  getTlitGraphClassCount: () => `${tlitApi}/ontology/graph/class/count/1.0?limit=-1&offset=0`,
  getTlitGraphTplCount: () => `${tlitApi}/ontology/graph/triple/count/1.0?limit=-1&offset=0`,

  // dpp-api ontology
  getDPPProtegeList: () => `${dppApi}/ontology/protege/list/1.0?limit=-1&offset=0`,
  getDPPClassList: () => `${dppApi}/ontology/class/list/1.0?limit=-1&offset=0`,

  // TODO:若要增加專案時, 這邊要設定 api
};

export default services;
