import React from 'react';
// mui
import Box from '@mui/material/Box';
//
// hooks
// route config

import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
//
import EasyDrawer from './EasyDrawer';

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

interface Props {
  children: React.ReactNode;
}
const RootLayout: React.FC<Props> = ({ children }): React.JSX.Element => (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <EasyDrawer />
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {/* <DrawerHeader /> */}
      {children}
    </Box>
  </Box>
);
export default RootLayout;
