import { createReducer } from '@reduxjs/toolkit';
import ontoAction from './ontoAction';

interface InitObjProp {
  pageName: string;
}

const initState: InitObjProp = {
  pageName: '',
};

const ontoReducer = createReducer(initState, (builder) => {
  builder.addCase(ontoAction.SET_PAGENAME, (state, action) => ({
    ...state,
    pageName: action.payload,
  }));
});

export default ontoReducer;
