import { configureStore } from '@reduxjs/toolkit';

// reducers
import homeReducer from '../pages/Home/homeReducer';
import ontoReducer from '../pages/Ontology/ontoReducer';
import authTokenReducer from './authReducer/authReducer';

import {
  timeoutReducer,
  timeoutAlertReducer,
  snackbarReducer,
} from './mainReducer/mainReducer';

// 將要用的reducer都放入下方object
const rootReducer = configureStore({
  reducer: {
    home: homeReducer,
    onto: ontoReducer,
    auth: authTokenReducer,
    timeoutStore: timeoutReducer.reducer,
    timeoutAlertStore: timeoutAlertReducer.reducer,
    snackbarStore: snackbarReducer.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default rootReducer;

/* 以下為 @type redux 定義 */

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer.getState>; // .getState 獲取 reducer裡面的屬性
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof rootReducer.dispatch;
