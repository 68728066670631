import enMessages from './en';
// import zhMessages from './zh-tw';
// import frMessages from './fr';

//
const languages: { [key:string]:{ [subKey:string]:string } } = {
  en: enMessages,
  // fixme: 待其他語系檔案完成, 再開放
  // 'zh-tw': zhMessages,
  // fr: frMessages,
};

export const supportLanguages = [
  { value: 'en', label: 'EN', labelLong: 'English' },
  // { value: 'fr', label: 'FR', labelLong: 'Française' },
  // { value: 'zh-tw', label: 'ZH', labelLong: '中文(繁體)' },
];

export default languages;
