import React, { Suspense } from 'react';

// react components
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
//
import IndexProvider from '../../reduxStore/IndexProvider';
import Intl18Provider from '../../intl18Store/Intl18Provider';

// route config
import routesPage from '../../config/config.route';

// components
import DataLayer from '../../data/DataLayer';
import LayoutWrapper from './subComponents/LayoutWrapper';
import ErrorPage from '../../pages/Error/ErrorPage';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';

function AllInOneProvider({ children }: { children: React.ReactNode }) {
  return (
    <IndexProvider>
      <Intl18Provider>
        <DataLayer>{children}</DataLayer>
      </Intl18Provider>
    </IndexProvider>
  );
}

function RouteManager() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {routesPage
            && routesPage
              .filter((r) => r.public)
              .map((route) => (
                <Route
                  element={(
                    <AllInOneProvider>
                      <Suspense>
                        <LayoutWrapper />
                      </Suspense>
                    </AllInOneProvider>
                  )}
                  key={route.id}
                  errorElement={<ErrorPage />}
                >
                  <Route
                    path={`/:locale?${route.path}`}
                    element={(
                      <Suspense>
                        <route.component />
                      </Suspense>
                    )}
                  />
                </Route>
              ))}
        <Route path="*" element={<Navigate to="/:locale/NotFound" />} />
        <Route
          path="/:locale/NotFound"
          element={(
            <AllInOneProvider>
              <Suspense>
                <NotFoundPage />
              </Suspense>
            </AllInOneProvider>
          )}
        />
      </Route>,
    ),
  );
  return <RouterProvider router={router} />;
}

export default RouteManager;
