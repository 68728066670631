import { createReducer } from '@reduxjs/toolkit';
import homeAct from './homeAction';

interface InitObjProp {
  pageName: string;
}

const initState: InitObjProp = {
  pageName: '',
};

const homeReducer = createReducer(initState, (builder) => {
  builder.addCase(homeAct.SET_PAGENAME, (state, action) => ({
    ...state,
    pageName: action.payload,
  }));
});

export default homeReducer;
