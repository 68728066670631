const rtName = {
  HOME: '/',
  NOTFOUND: '/NotFound',
  // console and sub routes
  ONTOLOGY: '/ontology', // for index and children routes
  //
};
export const routes = {
  ...rtName,
  HIDDENBUTTONSPATHS: [],
  HIDDEN_SIGNUP_BTN: [rtName.HOME] as string[],
  HIDDEN_LOGIN_BTN: [] as string[],
  EXPAND_NAVBAR: [] as string[],
};

export default routes;
