const role = {
  admin: 'admin',
  editor: 'editor',
  reader: 'reader',
  developer: 'developer',
  anonymous: 'anonymous',
  handler: 'handler',
};

export default role;
