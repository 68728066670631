// React
import React from 'react';
import { getApps, initializeApp } from 'firebase/app';
// MUI
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
// components
import Body from './main/Body';
// theme
import customTheme from './styles/customTheme';
//
import { firebaseConfig } from './config/config-firebase';
// CSS
import './styles/globals.scss';

if (getApps().length < 0) {
  initializeApp(firebaseConfig);
}

// main
function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Body />
    </ThemeProvider>
  );
}

export default App;
