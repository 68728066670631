import { createReducer } from '@reduxjs/toolkit';
import authAct, { ProjectInfo } from './authAction';

export interface AuthToken {
  token: string;
  projectTokens: ProjectInfo[];
}

const initState: AuthToken = {
  token: '',
  projectTokens: [],
};

const authReducer = createReducer(initState, (builder) => {
  builder.addCase(authAct.SET_TOKEN, (state, action) => ({
    ...state,
    token: action.payload,
  }));
  builder.addCase(authAct.CLEAR_TOKEN, (state) => ({
    ...state,
    token: initState.token,
  }));
  builder.addCase(authAct.SET_PRJ_TOKEN, (state, action) => ({
    ...state,
    projectTokens: action.payload,
  }));
  builder.addCase(authAct.CLEAR_PRJ_TOKEN, (state) => ({
    ...state,
    projectTokens: initState.projectTokens,
  }));
});

export default authReducer;
