import React from 'react';
//
import firestoreListener from './firestoreListener';

interface Props {
  children: React.ReactNode;
}

function DataLayer({ children }: Props) {
  // handle firebase user login status

  // handle firestore listener
  firestoreListener();

  return children ? (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : null;
}

export default DataLayer;
