import { createAction } from '@reduxjs/toolkit';

export interface ProjectInfo {
  project: string;
  token:string;
  [key:string]:string;
}

const authAct = {
  SET_TOKEN: createAction<string>(
    'SET_TOKEN',
  ),
  CLEAR_TOKEN: createAction<null>(
    'CLEAR_TOKEN',
  ),
  SET_PRJ_TOKEN: createAction<ProjectInfo[]>(
    'SET_PRJ_TOKEN',
  ),
  CLEAR_PRJ_TOKEN: createAction<null>(
    'CLEAR_PRJ_TOKEN',
  ),
};
export default authAct;
